<template>
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="container-fluid">
            <div class="contact-details">
                <div class="container-fluid">
                    <div class="contact-details-heading">
                        <p>MENU</p>
                        <div class="rearrange-buttons">
                            <button v-show="add_category_button" class="add-menu-btn">
                                <router-link style="background: linear-gradient(90deg, #EC7576 0%, #E0407A 100%); border-radius: 10px; border:none; color:white;  padding: 12px 31px;  cursor: pointer;"   :to="{ name: 'AddMenuItem'}" replace>+ ADD NEW ITEM</router-link>                                
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="">
                                    <path d="M8 1V15" stroke="url(#paint0_linear)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1 8H15" stroke="url(#paint1_linear)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <defs>
                                        <linearGradient id="paint0_linear" x1="8" y1="15" x2="9" y2="15" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#ffffff"/>
                                            <stop offset="1" stop-color="#ffffff"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear" x1="1" y1="9" x2="15" y2="9" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#ffffff"/>
                                            <stop offset="1" stop-color="#ffffff"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                ADD NEW ITEM -->
                            </button>
                            <button @click="reaarrange_data()" v-show="rearrange_button" class="arrange-menu-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 458.379 458.379" style="enable-background:new 0 0 458.379 458.379;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <g>
                                                <circle cx="53.792" cy="53.311" r="53.311"/>
                                                <circle cx="404.908" cy="53.311" r="53.311"/>
                                                <circle cx="229.35" cy="53.311" r="53.311"/>
                                                <circle cx="53.472" cy="229.19" r="53.312"/>
                                                <circle cx="404.587" cy="229.19" r="53.312"/>
                                                <circle cx="229.03" cy="229.19" r="53.312"/>
                                                <circle cx="53.472" cy="405.068" r="53.311"/>
                                                <circle cx="404.587" cy="405.068" r="53.311"/>
                                                <circle cx="229.03" cy="405.068" r="53.311"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                </svg>
                                RE-ARRANGE MENU
                            </button>
                        </div>
                        <div v-show="save_button" class="rearrange-save-btn">
                            <button @click="save_reaarrange()">SAVE CHANGES</button>
                        </div>
                    </div>




                    <!-- <div class="contact-details-heading">
                        <p>MENU</p>
                    </div>
                    <div class="add-item-button">
                        <button>
                            <router-link style="background: linear-gradient(90deg, #EC7576 0%, #E0407A 100%); border-radius: 10px; border:none; color:white;  padding: 12px 31px;  cursor: pointer;"   :to="{ name: 'AddMenuItem'}" replace>+ ADD NEW ITEM</router-link>
                        </button>
                    </div> -->





                </div>
                <div class="border-bottom">
                </div>
                <div class="address-details">
                    <div class="container-fluid">
                        <!-- accordian -->
                        <div v-show="main_data" id="main">
                            <div class="container-fluid">
                                <div class="accordion" id="faq">
                                    <!-- card1 -->
                                    <div class="card " v-for="(result,index) in results" :key="index">
                                        <div class="card-header" :id="'faqhead1'+index">
                                            <div class="first-card-main">
                                                <div class="first-card-main-inner">
                                                    <p>{{result.category.name}} </p>
                                                    <span id="items-c" > {{result.items.length}} items</span>

                                                </div>
                                                <div class="disable-para">This is an Inactive category, Users can click it and switch on the toggle to activate
                                                </div>
                                                <div class="first-card-main-inner1">
                                                    <div class="add-new-item">
                                                        <router-link     :to="{ name: 'AddMenuItem', params: {id: result.category.id } }" replace>+ ADD NEW ITEM</router-link>
                                                    </div>
                                                    <div class="right-icon">
                                                        <a href="#" class="btn btn-header-link" data-toggle="collapse"  :data-target="'#faq1'+index" aria-expanded="true" :aria-controls="'faq1'+index" >
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div  :id="'faq1'+index"
                                        :class="['collapse ', index==0 ? 'show myclass' : '']"
                                        :aria-labelledby="'faqhead1'+index" data-parent="#faq">
                                            <div class="card-body">
                                                <!-- strt -->
                                                <div class="first-detail">
                                                    <div class="table-responsive">
                                                        
                                                        <table class="table">
                                                            <thead>
                                                                <tr class="top-tr">
                                                                    <th id="item-tr" scope="col">Item</th>
                                                                    <th scope="col">Price</th>
                                                                    <th scope="col">Available</th>
                                                                    <th scope="col">Active</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item,index) in result.items" :key="index"  >
                                                                    <th scope="row" >
                                                                        <div class="dish">
                                                                            <div class="media">
                                                                                <img v-if='item.image' class="mr-3" style="width: 53px;height: 50px;border-radius: 5px;" :src="item.image"  alt="img">


                                                                                <img  v-else class="mr-3" style="width: 53px;height: 50px;border-radius: 5px;"  :src="require('@/assets/images/dummy.jpg')">
                                                                             
                                                                                <div class="media-body">
                                                                                    <h5 class="mt-0">
                                                                                        {{item.name}}
                                                                                    </h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <td >
                                                                        <div class="price">
                                                                            <p href="#">${{item.price}}</p>

                                                                        </div>
                                                                    </td>
                                                                    <td >
                                                                        <div class="available">
                                                                            <div class="switch-2">
                                                                                <label class="switch-inner1">
                                                                                    <input type="checkbox" id="togBtn4"
                                                                                    @change="check_available_boolean($event,item.id,result.category.id)"
                                                                                  
                                                                                    :checked="!item.sold_out">
                                                                                    <div   :class="['slider ', item.sold_out === true ? 'round disabled' : 'round ']">
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td >
                                                                        <div class="daily-limit">
                                                                            <div class="switch-2">
                                                                                <label class="switch-inner1">
                                                                                <input type="checkbox" id="togBtn4" v-model="item.is_active"  @change="check_active_boolean($event,item.id,result.category.id)">
                                                                                    <div :class="['slider ', item.is_active === true ? 'round' : 'round disabled']">
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td >
                                                                        <div class="edit">
                                                                            <div class="dropdown-icon">
                                                                                <button class="dropbtn">
                                                                                        <span class="text-icon">
                                                                                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                        </span>
                                                                                </button>
                                                                                <div class="dropdown-content">
                                                                                    <div class="arrow-up"></div>
                                                                                    <router-link   :to="{ name: 'EditMenuItem', params: {id: item.id } }" replace><i class="fa fa-pencil-square-o" aria-hidden="true"> </i>Edit</router-link>
                                                                                    <hr>
                                                                                    <div class="del">
                                                                                        <a href="#" data-toggle="modal" :data-target="'#exampleModal18'+item.id"
                                                                                        ><i class="fa fa-trash-o" aria-hidden="true"> </i>Delete</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <!-- modal -->
                                                                    <div  class="modal fade" 
                                                                    :id="'exampleModal18'+item.id"
                                                                    tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                        <div class="modal-dialog" role="document">
                                                                            <div class="modal-content">
                                                                                <div class="modal-header">
                                                                                    <h5 class="modal-title  mx-auto" id="exampleModalLabel18">Delete Item</h5>
                                                                                </div>
                                                                                <div class="modal-body">
                                                                                    <p>Are you sure you want to delete "{{item.name}}" from the menu? </p>
                                                                                </div>
                                                                                <div class="modal-footer">
                                                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                                                    <button data-dismiss="modal" @click="delete_menu_item(item.id)" type="button" class="btn btn-primary">Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="enter-category">
                                    <div class="buttons-input1" v-show="addNewCategoryInput">
                                        <input  type="text" id="fname" name="firstname" placeholder="Enter Category Name" v-model="addNewCategory">
                                        <div class="buttons-input">
                                            <button @click="discard_add_category()" id="cancel-input">Cancel</button>
                                            <button  @click="save_add_category()" id="save-input">Save</button>
                                        </div>
                                    </div>
                                    <span v-if="addNewCategory_error" class="error_alert">{{addNewCategory_error_message}}</span>

                                </div>
                                <div class="add-menu-category">
                                    <p href="#" @click="showMenuCategoryInput()">+ ADD MENU CATEGORY</p>

                                </div>
                            </div>

                       
                        </div>

                        <div v-show="draggable_data"  class="arrange-menuitems">
                            <div id="main1">
                                <div class="container-fluid">
                                    <div class="accordion" id="faq">
                                        <!-- card1 -->

                                        <draggable v-model="results"  @start="drag=true" @end="drag=false" @change="update_menu_category_order()">


                                            <div  class="card" v-for="(result,index) in results" :key="index">
                                                <div class="card-header" :id="'faqhead1-rearrange'+index">
                                                    <a href="#" class="btn btn-header-link" data-toggle="collapse"  :data-target="'#faq1-rearrange'+index" aria-expanded="true" :aria-controls="'faq1-rearrange'+index">
                                                    {{result.category.name}}
                                                        <span id="items-c">{{result.items.length}} items </span>
                                                        <div class="rearrange-icons">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 458.379 458.379" style="enable-background:new 0 0 458.379 458.379;" xml:space="preserve">
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <circle cx="53.792" cy="53.311" r="53.311"></circle>
                                                                            <circle cx="404.908" cy="53.311" r="53.311"></circle>
                                                                            <circle cx="229.35" cy="53.311" r="53.311"></circle>
                                                                            <circle cx="53.472" cy="229.19" r="53.312"></circle>
                                                                            <circle cx="404.587" cy="229.19" r="53.312"></circle>
                                                                            <circle cx="229.03" cy="229.19" r="53.312"></circle>
                                                                            <circle cx="53.472" cy="405.068" r="53.311"></circle>
                                                                            <circle cx="404.587" cy="405.068" r="53.311"></circle>
                                                                            <circle cx="229.03" cy="405.068" r="53.311"></circle>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                                <g></g>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div :id="'faq1-rearrange'+index"  
                                                :class="['collapse ', index==0 ? 'show' : '']"  :aria-labelledby="'faqhead1-rearrange'+index" data-parent="#faq">
                                                    <div class="card-body">
                                                        <div class="first-detail">
                                                            <div class="table-responsive">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr class="top-tr">
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <draggable v-model="result.items"  @start="drag=true" @end="drag=false" @change="update_menu_item_order(result.items)">
                                                                            <tr  v-for="(item,index) in result.items" :key="index" >
                                                                                <th scope="row">
                                                                                    <div class="dish">
                                                                                        <div class="media">

                                                                                            <img v-if='item.image' class="mr-3" style="width: 53px;height: 50px;border-radius: 5px;" :src="item.image"  alt="img">


                                                                                            <img  v-else class="mr-3" style="width: 53px;height: 50px;border-radius: 5px;"  :src="require('@/assets/images/dummy.jpg')">


                                                                                            <div class="media-body">
                                                                                                <h5 class="mt-0">
                                                                                                    {{item.name}}
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </th>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 458.379 458.379" style="enable-background:new 0 0 458.379 458.379;" xml:space="preserve">
                                                                                        <g>
                                                                                            <g>
                                                                                                <g>
                                                                                                    <circle cx="53.792" cy="53.311" r="53.311"></circle>
                                                                                                    <circle cx="404.908" cy="53.311" r="53.311"></circle>
                                                                                                    <circle cx="229.35" cy="53.311" r="53.311"></circle>
                                                                                                    <circle cx="53.472" cy="229.19" r="53.312"></circle>
                                                                                                    <circle cx="404.587" cy="229.19" r="53.312"></circle>
                                                                                                    <circle cx="229.03" cy="229.19" r="53.312"></circle>
                                                                                                    <circle cx="53.472" cy="405.068" r="53.311"></circle>
                                                                                                    <circle cx="404.587" cy="405.068" r="53.311"></circle>
                                                                                                    <circle cx="229.03" cy="405.068" r="53.311"></circle>
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                        <g></g>
                                                                                    </svg>
                                                                                </td>
                                                                            </tr>
                                                                                                                                                                                        </draggable>

                                                                    
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </draggable>

                                    </div>
                                </div>
                            </div>
                        </div>

                    
                    </div>
                </div>
            </div>
        </div>
    </div>
    

</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'
  import draggable from 'vuedraggable'

    export default {
        components: {
            draggable,
        },
        name: 'BrandsMenu',
        data () {
            return {
                loading:false,
                results:[],
                addNewCategoryInput:false,
                addNewCategory:'',
                addNewCategory_error:false,
                addNewCategory_error_message:'',
                categoryStatus:true,

                rearrange_button:true,
                add_category_button:true,
                main_data:true,

                save_button:false,
                draggable_data:false,

                interval_options:[
                    '15 mins',
                    '30 mins',
                    '45 mins',
                    '1 hr',
                    '1 hr 30 mins',
                    '2 hrs',
                    'other',

                ],


            }
        },
        methods: {

            showMenuCategoryInput(){
                this.addNewCategoryInput=true
            },
            changeTheme(){
                const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
                function switchTheme(e) {
                    if (e.target.checked) {
                        document.documentElement.setAttribute('data-theme', 'light');
                        localStorage.setItem('theme', 'dark'); 
                    }
                    else {
                        document.documentElement.setAttribute('data-theme', 'dark');
                    }    
                }
                toggleSwitch.addEventListener('change', switchTheme, false);
                const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;
                if (currentTheme) {
                    document.documentElement.setAttribute('data-theme', currentTheme);
                    if (currentTheme === 'light') {
                        toggleSwitch.checked = true;
                    }
                }


            },
            discard_add_category(){
                this.addNewCategory=''
            },
            async save_add_category(){
                this.errors=[]
                this.addNewCategoryInput=false
                if (this.validate()) {
                    this.loading= true;
                    try{
                        const data =  { 
                            "name": this.addNewCategory,
                            'brand':this.$store.state.brand
                        };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var api_url = `${baseUrl}/api/categories/`
                        const response =  await axios.post(api_url,data,{headers})
                        console.log('-response-',response)
                        this.loading= false;
                        await this.$toasted.success("Menu Category Added Successfully and will be shown once menu item is assigned to it.");
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }
                }
            },
            
            validate(){
                this.addNewCategory_error=false
                if ((!this.addNewCategory) || (this.addNewCategory<=0)) {
                    this.addNewCategory_error=true
                    this.addNewCategory_error_message="Please provide Menu Category Name!"
                    this.errors.push("Please provide Menu Category Name!")
                    this.$toasted.error('Please provide Menu Category Name!');
                }
                if (Number(this.addNewCategory)) {
                    this.addNewCategory_error=true
                    this.addNewCategory_error_message="Menu Category Name can't be entirely numeric."
                    this.errors.push("Menu Category Name can't be entirely numeric.")
                    this.$toasted.error('Your password cannot be entirely numeric.');
                }
                if (!this.errors.length) {
                    return true;
                }
                 
                 
            },

            async delete_menu_item(id){
                this.loading= true;
                try{
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var api_url = `${baseUrl}/api/menu-items/`+id+`/`
                    const response =  await axios.delete(api_url,{headers})
                    console.log('-response-',response)
                    this.loading= false;
                    await this.$toasted.success("Menu Item Deleted Successfully");
                    try{
                        this.loading= true;
                        var brand_id = this.$store.state.brand
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        const params =  {  "brand": brand_id};
                        var my_api_url = `${baseUrl}/api/menu-items/`
                        const response =  await axios.get(my_api_url,{params: params,headers:headers})
                        this.loading= false;
                        this.results = response.data.results;
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }



                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }


            },

            async check_available_boolean(e,menu_item_id,category){
                this.loading= true;
                try{
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    let formData = new FormData();
                    formData.append('brand', brand_id);
                    formData.append('category', category);
                    if(e.target.checked==true){
                        formData.append('sold_out', false);
                    }
                    else{
                        formData.append('sold_out',true);
                    }
                    var api_url = `${baseUrl}/api/menu-items/`+menu_item_id+`/`
                    const response =  await axios.patch(api_url,formData,{headers})
                    console.log('-response-',response)
                    this.loading= false;
                    try{
                        this.loading= true;
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        const params =  {  "brand": brand_id};
                        var my_api_url = `${baseUrl}/api/menu-items/`
                        const response =  await axios.get(my_api_url,{params: params,headers:headers})
                        this.loading= false;
                        this.results = response.data.results;
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }

                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }



            },

            async check_active_boolean(e,menu_item_id,category){
                this.loading= true;
                try{
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var brand_id = this.$store.state.brand
                    let formData = new FormData();
                    formData.append('brand', brand_id);
                    formData.append('category', category);

                    if(e.target.checked==true){
                        formData.append('is_active', true);
                    }
                    else{
                        formData.append('is_active',false);
                    }
                    var api_url = `${baseUrl}/api/menu-items/`+menu_item_id+`/`
                    const response =  await axios.patch(api_url,formData,{headers})
                    console.log('-response-',response)
                    this.loading= false;
                    try{
                        this.loading= true;
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        const params =  {  "brand": brand_id};
                        var my_api_url = `${baseUrl}/api/menu-items/`
                        const response =  await axios.get(my_api_url,{params: params,headers:headers})
                        this.loading= false;
                        this.results = response.data.results;
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }

                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }



            },


            // moving menu items
            reaarrange_data(){
                this.rearrange_button=false
                this.add_category_button=false
                this.main_data=false
                this.draggable_data=true
                this.save_button=true
            },
            save_reaarrange(){
                this.rearrange_button=true
                this.add_category_button=true
                this.main_data=true
                this.draggable_data=false
                this.save_button=false

            },

        

       





            async update_menu_category_order(){
                try{
                    console.log('++++++++++++++++++++++',this.results)
                    this.errors=[]
                    this.loading= true;
                    var menu_category_list=[]
                    for (i = 0; i < this.results.length; ++i) {
                        const my_data =  { 
                        'brand':this.$store.state.brand,
                        "category": this.results[i].category.id,
                        "order": i
                        };
                        menu_category_list.push(my_data)
                    }
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    console.log('menu_category_list',menu_category_list)
                    var api_url = `${baseUrl}/content-management/brand-category-orders/bulk/`
                    const response =  await axios.post(api_url,menu_category_list,{headers})
                    console.log('-response-',response)
                    this.loading= false;
                    await this.$toasted.success("Menu Category Rearranged Successfully.");
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

            async update_menu_item_order(result){
                try{
                    console.log('============items===========',result)
                    this.errors=[]
                    this.loading= true;
                    var menu_item_list=[]
                    for (i = 0; i < result.length; ++i) {
                        const my_data =  { 
                        'brand':this.$store.state.brand,
                        "menu_item": result[i].id,
                        "order": i
                        };
                        menu_item_list.push(my_data)
                    }

                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var api_url = `${baseUrl}/content-management/brand-menuitem-orders/bulk/`
                    const response =  await axios.post(api_url,menu_item_list,{headers})
                    console.log('-response-',response)
                    this.loading= false;
                    await this.$toasted.success("Menu Items Rearranged Successfully.");
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
                


            }




        },
        mounted(){

        },
        async created(){
            try{
                this.loading= true;
                var brand_id = this.$store.state.brand
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                const params =  {  "brand": brand_id,
                'limit':10000 };
                var api_url = `${baseUrl}/api/menu-items/`
                const response =  await axios.get(api_url,{params: params,headers:headers})
                this.loading= false;
                this.results = response.data.results;
                console.log('=========menu categories response=====',this.results)
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                var i;
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }
            



        }
    }

</script>


<style>

</style>